import { useSelector } from 'react-redux';
import { useContext, useEffect, Suspense, useMemo } from 'react';

import { selectOrganization } from '@store/organization/selectors';
import * as selectors from '@store/profile/selectors';
import { selectIsExternalUser } from '@store/application/selectors';
import { useCustomization, useVisits } from '@effects/application';
import { dispatchCompanyDefaults } from '@utils/application';
import { AppContext } from '~/contexts/app';
import * as analytics from '@utils/analytics';
import { useAllBankAccountsHook } from '~/entities/ach';
import { useOrganizationSettings } from '~/entities/Organization';

import Loader from '~/ui/components/Loader/Loader';

import { SwitchRouteLayoutWrapper } from '../RouteLayout';

import { getAccountRoutes } from './accountRoutes';
import { getClosedAccountRoutes } from './closedAccountRoutes';
import { getNoAccountRoutes } from './noAccountRoutes';
import { useMainNotifications } from './hooks';

export const Navigation = () => {
  const appDispatch = useContext(AppContext);
  const organization = useSelector(selectOrganization);
  const profileInfo = useSelector(selectors.selectProfile);
  const isExternalUser = useSelector(selectIsExternalUser);
  const companySettings = useOrganizationSettings();
  const hasAlpacaAccount = selectors.getHasAlpacaAccount();
  const closure = selectors.getIsAccountClosure();
  const isProfileExist = !!profileInfo;
  const [_, preloadBankAccounts] = useAllBankAccountsHook();

  useMainNotifications(profileInfo);

  const applicationNavigationRoutes = useMemo(() => {
    if (!isProfileExist) {
      return getNoAccountRoutes(isExternalUser);
    }

    if (closure) {
      return getClosedAccountRoutes(hasAlpacaAccount);
    }

    preloadBankAccounts();

    return getAccountRoutes(hasAlpacaAccount);
  }, [hasAlpacaAccount, closure, isProfileExist, isExternalUser, preloadBankAccounts]);

  useCustomization(organization);
  useVisits();
  useEffect(() => {
    analytics.page();
  }, []);

  useEffect(() => {
    dispatchCompanyDefaults(appDispatch, companySettings);
  }, [appDispatch, companySettings]);

  return (
    <Suspense fallback={<Loader />}>
      <SwitchRouteLayoutWrapper>{applicationNavigationRoutes}</SwitchRouteLayoutWrapper>
    </Suspense>
  );
};
